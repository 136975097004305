import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import styled from "styled-components";

const avocado = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743379700/avocado_izozwa.jpg';

export default function Giving() {
    return (
        <Flex {...styles.Container}>
            <Header/>            
            <Flex {...styles.Body}>
                <Flex {...styles.ContentContainer}> 
                    <Image src={avocado} {...styles.DinnerTableImage}/>
                    <Flex {...styles.RightContainer}>
                    <Text {...styles.SubText}><BoldSpan>Aiming to restore the climate, one meal at a time</BoldSpan></Text>
                        <Text {...styles.GivingParagraphText}>
                            Created by a chef, Le Savoir Fare recognizes the unique relationship that culinary professionals have with the world. As chefs, we nourish communities and families while fostering a deeper connection with the people we feed. Simultaneously, we share an intrinsic bond with nature and the resources it provides. This dual commitment drives us to pursue practices that promote the well-being of individuals, animals, and the environment.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            Founded to address the escalating urgency of the global food and climate crisis, this initiative underscores the fragility of our food systems and the need for a deeper understanding of how our choices impact both people and the planet. Rooted in education and advocacy, its mission is to inspire a shift toward more sustainable and regenerative food practices, empowering individuals to rethink their relationship with food—from how it’s grown to how it’s enjoyed.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            Advocating for responsible farming and ethical production methods is at the heart of this mission, prioritizing the health of both communities and the planet. Partnerships with organizations like Zero FoodPrint and the Regenerative Organic Alliance further strengthen these efforts, advancing a more sustainable and equitable future.
                        </Text>
                        <Flex {...styles.ButtonRow}>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://www.zerofoodprint.org/")
                            }}>
                                <Text {...styles.ButtonText}>Zero FoodPrint</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://regenorganic.org/")
                            }}>
                                <Text {...styles.ButtonText}>Regenerative Organic Alliance</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("/contact-us")
                            }}>
                                <Text {...styles.ButtonText}>Le Savoir Fare</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const BoldSpan = React.memo(styled.span`
    font-family: ${fonts.cormorandSemiblold}
`);
const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    SubText: {
        fontFamily: fonts.cormorand,
        fontSize: ["20px", "24px"],
        lineHeight: "24px",
        color: colors.olive,
        maxWidth: "750px",
        textAlign: "center",
        paddingBottom: ["40px", "60px"]
    },
    Body: {
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
        width: "100vw",
    },
    ContentContainer: {
        backgroundColor: colors.beigeBackground,
        width: "100%",
        flexDirection: ["column-reverse", "row"],
        justifyContent: "flex-start"
    },
    DinnerTableImage: {
        width: ["100%", "50%"]
    },
    RightContainer: {
        width: ["100%", "50%"],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "45px",
    },
    GivingTitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: ["20px", "25px"],
        color: colors.olive,
        paddingBottom: "70px",
        maxWidth: ["85vw", "425px"],
    },
    GivingParagraphText: {
        fontFamily: fonts.cormorand,
        fontSize: ["18px", "20px"],
        color: colors.olive,
        paddingBottom: "40px",
        maxWidth: ["85vw", "500px"],
        textAlign: "justify"
    },
    ButtonRow: {
        flexDirection: ["column", "row"],
        width: [null, "555px"],
        height: ["230px", null],
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: ["10px", "0px"],
        paddingBottom: ["60px", null]
    },
    ButtonText: {
        color: colors.beigeBackground,
        fontFamily: fonts.cormorand,
        fontSize: "18px",
    },
    Button: {
        backgroundColor: colors.olive,
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingTop: "12px",
        paddingBottom: "12px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        hoverOpacity: "0.9"
    }

}