import React, { useEffect, useState } from "react";
import Flex from "../ui/Flex";
import Image from "../ui/Image";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import plantIcon from "../../assets/images/plant-thin.svg";
import sparkleIcon from "../../assets/images/sparkle-thin.svg";
import diamondIcon from "../../assets/images/sketch-logo-thin.svg";
import booksIcon from "../../assets/images/books-thin.svg";
import grainsIcon from "../../assets/images/grains-thin.svg";
import asteriskIcon from "../../assets/images/asterisk-simple-thin.svg";

const crabImageUrl = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743370587/crab_horizontal_be1qb1.jpg';
const quailEggsImageUrl = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743371887/quaileggs_zlmvas.jpg';
const mushroomsImageUrl = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743370590/oyster_horizontal_wdrjbx.png';

const StyledList = styled.ul`
    padding-inline-start: 10px;
`;

const StyledListGrainsBullets = styled.ul`
    padding-inline-start: 10px;
    list-style: none;
    list-style-type:none;
`;


export default function Home() {
    const navigate = useNavigate();
    const [mobileHeroHeight, setMobileHeroHeight] = useState(`${window.innerHeight}px`);
    const [mobileRightSideContainerHeight, setMobileRightSideContainerHeight] = useState(`${window.innerHeight - 135}px`);

    useEffect(() => {
        const handleResize = () => {
            setMobileHeroHeight(`${window.innerHeight}px`);
            setMobileRightSideContainerHeight(`${window.innerHeight - 135}px`);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Flex {...styles.Container}>
            <Header/>
                <Flex {...styles.MainContentContainer}>
                    <Image src={mushroomsImageUrl} {...styles.MushroomImage} />
                    <Flex {...styles.TextContainer}>
                        <Text {...styles.TitleText} paddingTop="40px">Origin</Text>
                        <Text {...styles.paragraphText}>
                            'Savoir Faire' is a French expression dating back to the 19th century, directly translating to 'know-how.' Traditionally used across various industries, it represents creativity, innovation, meticulous attention to detail, and luxury—qualities that define exceptional craftsmanship. By replacing the French 'faire' with the English 'fare,' which refers to an array of foods, the phrase emphasizes expertise in preparing a diverse range of cuisines.                                
                        </Text>
                        <Text {...styles.TitleText}>What is Le Savoir Fare?</Text>
                        <Text {...styles.paragraphText}>
                            A private culinary consultancy merging culinary artistry with the science of nutrition and cooking—rooted in:
                        </Text>
                        <Flex {...styles.WhatIsLSFRow}>
                            <Flex {...styles.WhatIsLSFColumn}>
                                <Flex {...styles.IconTextRow}>
                                    <Image src={plantIcon} {...styles.ListIcon}/>
                                    <Text {...styles.paragraphText} paddingBottom="0px">
                                        Regenerative agriculture
                                    </Text>
                                </Flex>

                                <Flex {...styles.IconTextRow}>
                                    <Image src={sparkleIcon} {...styles.ListIcon}/>
                                    <Text {...styles.paragraphText} paddingBottom="0px">
                                        Clean living
                                    </Text>
                                </Flex>
                                </Flex>
                            <Flex {...styles.WhatIsLSFColumn}>
                                <Flex {...styles.IconTextRow}>
                                    <Image src={diamondIcon} {...styles.ListIcon}/>
                                    <Text {...styles.paragraphText} paddingBottom="0px">
                                        High-touch, white glove experience
                                    </Text>
                                </Flex>
                                <Flex {...styles.IconTextRow}>
                                    <Image src={booksIcon} {...styles.ListIcon}/>
                                    <Text {...styles.paragraphText} paddingBottom="0px">
                                        Education and advocacy
                                    </Text>
                                </Flex>
                            </Flex>


                        </Flex>
                    </Flex>


                    <Image src={crabImageUrl} {...styles.CrabImage}/>


                    <Flex {...styles.TextContainer}>
                        <Text {...styles.TitleText} marginTop="40px">
                            Is this experience designed for you?
                        </Text>
                        <Text {...styles.paragraphText} paddingBottom="15px" maxWidth="900px">
                        <StyledListGrainsBullets>
                            <ListItem>
                                <Image src={asteriskIcon} {...styles.asteriskIcon} />
                                Do you <BoldSpan>believe</BoldSpan> that cultivating a sustainable culinary lifestyle requires time, effort, and intention—and are you ready to take an active role in a meaningful, transformative shift rather than seeking a temporary fix?
                            </ListItem>
                            <ListItem>
                                <Image src={asteriskIcon} {...styles.asteriskIcon} />
                                Do you <BoldSpan>appreciate</BoldSpan> meticulously crafted, high-touch, concierge-level experiences that foster culinary confidence and intuitive nourishment? 
                            </ListItem>
                            <ListItem>
                                <Image src={asteriskIcon} {...styles.asteriskIcon} />
                                Do you <BoldSpan>prioritize</BoldSpan> integrating high-quality, nutrient-dense meals into your life with intention—optimizing your health one meal at a time?
                            </ListItem>
                            <ListItem>
                                <Image src={asteriskIcon} {...styles.asteriskIcon} />
                                Are you <BoldSpan>seeking</BoldSpan> a refined, conscious, and sustainable approach to intentional nourishment?
                            </ListItem>
                            <ListItem>
                                <Image src={asteriskIcon} {...styles.asteriskIcon} />
                                Do you <BoldSpan>value</BoldSpan> a bespoke, evidence-based methodology paired with personalized mentorship?
                            </ListItem>
                        </StyledListGrainsBullets>
                        </Text>
                        <Text {...styles.paragraphText} paddingBottom="40px">
                            If this resonates, Le Savoir Fare is <BoldSpan>crafted for you.</BoldSpan> 
                        </Text>
                    </Flex>

                    
                    <Image src={quailEggsImageUrl} {...styles.CrabImage}/>

                    <Flex {...styles.TextContainer}>
                        <Text {...styles.TitleText} marginTop="40px" paddingBottom="40px">
                                        What's the Structure?
                        </Text>
                        <Text {...styles.paragraphText} paddingBottom="30px">
                            <BoldSpan>12 Months | 45 Sessions | 57 Total Hours</BoldSpan>
                        </Text>  
                        <Text {...styles.paragraphText}>
                        
                        <StyledListGrainsBullets>
                            <ListItem>
                                <Image src={grainsIcon} {...styles.grainsIcon} />
                                <BoldSpan>Phase I: Foundation </BoldSpan>(Months 1-3) → 24 Sessions | 36 Hours
                                <StyledListGrainsBullets>
                                    <ListSubItem>
                                        - Immersive, hands-on experience across all 7 modules
                                    </ListSubItem>
                                    <ListSubItem>
                                        - In-person sessions twice a week → 1-hour & 2-hour sessions
                                    </ListSubItem>
                                </StyledListGrainsBullets>
                            </ListItem>
                            <ListItem>
                                <Image src={grainsIcon} {...styles.grainsIcon} />
                                <BoldSpan>Phase II: Refinement</BoldSpan> (Months 4-6) → 12 Sessions | 12 Hours
                                <StyledListGrainsBullets>
                                    <ListSubItem>
                                        - Weekly one-on-one consultations
                                    </ListSubItem>
                                </StyledListGrainsBullets>
                            </ListItem>
                            <ListItem>
                                <Image src={grainsIcon} {...styles.grainsIcon} />
                                <BoldSpan>Phase III: Optimization </BoldSpan>(Months 7-9) → 6 Sessions | 6 Hours
                                <StyledListGrainsBullets>
                                    <ListSubItem>
                                    - Biweekly one-on-one consultations
                                    </ListSubItem>
                                </StyledListGrainsBullets>
                            
                            </ListItem>
                            <ListItem>
                                <Image src={grainsIcon} {...styles.grainsIcon} />
                                <BoldSpan>Phase IV: Integration </BoldSpan>(Months 10-12) → 3 Sessions | 3 Hours
                                <StyledListGrainsBullets>
                                    <ListSubItem>
                                    - Monthly one-on-one consultations
                                    </ListSubItem>
                                </StyledListGrainsBullets>
                            
                            </ListItem>
                        </StyledListGrainsBullets>

                        </Text>

                        <Text {...styles.paragraphText} paddingBottom="20px">
                            <BoldSpan>Start dates:</BoldSpan> January 5 | April 5 | July 5
                        </Text>
                        <Text {...styles.paragraphText} marginBottom="40px">
                            <BoldSpan>Exclusive enrollment:</BoldSpan> Limited to 4 clients per cycle.
                        </Text> 
                    </Flex>
                    
                </Flex>
                
            <Footer/>
        </Flex>
    );
}

const ListItem = styled.li`
    margin-bottom: 25px; /* Adds 10px spacing below each list item */
    
    &:last-child {
        margin-bottom: 0; /* Removes margin for the last item */
    }
`;
const ListSubItem = styled.li`
    margin-top: 7px; /* Adds 10px spacing below each list item */
    
    &:last-child {
        margin-bottom: 0; /* Removes margin for the last item */
    }
`;
const BoldSpan = React.memo(styled.span`
    font-family: ${fonts.cormorandSemiblold}
`);

// 588


// 3607 x 4509 Crab
// 906 x 571     Oyster
const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    HeroContainer: {
        width: "100vw",
        zIndex: 0,
        height: [null, "950px"],
        flexDirection: ["column-reverse", "row"]
    },
    CrabImage: {
        width: ["100vw", "100%"],
    },
    MushroomImage: {
        width: ["100vw", "100%"],
    },
    RightSideContainer: {
        width: ["100vw", "50vw"],
        height: [null, "1160px"],
        marginTop: ["0px", "-210px"],
    },
    HeroTextContainer: {
        width: ["350px", "50vw"],
        position: "absolute",
        right: ["50%", 0],
        marginRight: ["-175px", 0],
        top: ["240px", "400px"],
        zIndex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        jusifyContent: "center",
        paddingLeft: "50px"
    },
    TitleText: {
        color: colors.olive,
        fontSize: "35px",
        fontFamily: fonts.cormorandBlold,
        paddingBottom: "20px",
    },
    HeroSubtext: {
        color: colors.blueTeal,
        fontSize: "20px",
        fontFamily: fonts.cormorandLightItalic,
        paddingTop: "10px",
        cursor: "pointer",
    },
    MainContentContainer: {
        width: "100vw",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",

        maxWidth: "1200px",
        backgroundColor: colors.beigeBackground,
        overflowX: "hidden",
    },
    TextContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        paddingLeft: ["20px", "100px"],
        paddingRight: ["20px", "100px"],
    },
    paragraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "20px",
        color: colors.olive,
        paddingBottom: "35px",
        lineHeight: 1.5,
        textAlign: "justify",

    },
    WhatIsLSFRow: {
        flexDirection: ["column","row"],
        justifyContent: "space-evenly",
        alignItems: ["center","flex-start"],
        width: "100%",
        marginBottom: "40px",
    },
    WhatIsLSFColumn: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
        maxWidth: "330px",
    },
    ListIcon: {
        width: "20px",
        height: "20px",
        marginRight: "10px",
    },
    IconTextRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingBottom: ["0px","10px"],
    },
    grainsIcon: {
        // width: "20px",
        height: "16px",
        marginRight: "3px",
    },
    asteriskIcon: {
        height: "15px",
        marginRight: "3px",
    }
}