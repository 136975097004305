// src/context/AudioContext.js
import React, { createContext, useState, useEffect } from 'react';
import soundtrack from '../assets/audio/lsf_soundtrack.mp3';

export const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [audio] = useState(new Audio(soundtrack));
  const [isPlaying, setIsPlaying] = useState(false);

  const startAudio = () => {
    audio.loop = true;
    audio.play();
    setIsPlaying(true);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    audio.loop = true;
    audio.addEventListener('canplaythrough', () => {
      console.log('Audio can play through');
    });
    audio.addEventListener('error', (e) => {
      console.error('Audio error', e);
    });

    return () => {
      audio.removeEventListener('canplaythrough', () => {});
      audio.removeEventListener('error', () => {});
    };
  }, [audio]);

  return (
    <AudioContext.Provider value={{ isPlaying, togglePlay }}>
      {children}
    </AudioContext.Provider>
  );
};