import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Text from "../ui/Text";
import Image from "../ui/Image";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";


const citrus = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743379292/citrus_zr4gzb.jpg';

const StyledList = styled.ul`
    padding-inline-start: 10px;
`;

export default function Blueprint() {
    const navigate = useNavigate();
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Image src={citrus} {...styles.CitrusImage}/>
            <Flex {...styles.TextContainer}>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Blueprint Overview</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    This multi-faceted, science-driven culinary blueprint consists of <BoldSpan {...styles.Bold}>45 personalized sessions</BoldSpan> over <BoldSpan {...styles.Bold}>one year</BoldSpan>, designed to ensure measurable and sustainable success. Each module builds on the next, fostering a comprehensive foundation in nutrition, sustainability, culinary expertise, and intentional nourishment.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #1: "Science-Based Nutrition”</BoldSpan> An evidence-based approach to nutrition fundamentals, tailored to individual needs to enhance long-term well-being and optimize health.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #2: “Blue Zones"</BoldSpan> An introduction to the holistic health principles of Blue Zones populations, focusing on the key lifestyle factors that contribute to longevity and balance.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #3: “Bespoke Meal Strategy”</BoldSpan> A personalized culinary plan designed to align with individual dietary needs, preferences, and nutritional goals—supporting a sustainable, nourishing way of eating. To ensure the highest standard of nutritional integrity, all meal strategies are reviewed in collaboration with a registered dietitian or nutritionist.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #4: “Sustainable Food Sourcing”</BoldSpan> A guided approach to sourcing high-quality ingredients in alignment with ethical, environmental, and regenerative practices. Clients will gain the knowledge and tools to make informed decisions that support a more sustainable and responsible food system.
                </Text>
                <Text {...styles.paragraphText} {...{paddingBottom: "15px"}}>
                    <BoldSpan {...styles.Bold}>Module #5: “Kitchen, Fridge & Pantry Optimization”</BoldSpan> A strategic approach to optimizing kitchen flow, organization, and efficiency. This module covers ergonomic design, inventory management, and sustainable maintenance practices—ensuring a seamless, functional, and eco-conscious culinary space. The process has the following order:
                </Text>
                <Text {...styles.paragraphText} {...{paddingLeft: ["30px", "45px"], paddingBottom: "15px"}}>
                    <BoldSpan {...styles.Bold}>1. Initial Assessment:</BoldSpan> I will visit your space to evaluate layout, functionality, and system efficiency, providing tailored recommendations.
                </Text>
                <Text {...styles.paragraphText} {...{paddingLeft: ["30px", "45px"], paddingBottom: "15px"}}>
                    <BoldSpan {...styles.Bold}>2. Preparation & Execution:</BoldSpan> You or your household team will be responsible for removing all items, consolidating inventory, and completing a deep clean before I oversee the reorganization and refinement process.
                </Text>
                <Text {...styles.paragraphText} {...{paddingLeft: ["30px", "45px"], paddingBottom: "15px"}}>
                    <BoldSpan {...styles.Bold}>3. Optimization & Supervision:</BoldSpan> Once the space is prepped, I will oversee the reorganization, refine storage systems, and implement practical solutions to enhance efficiency and sustainability.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #6: “Culinary Fundamentals”</BoldSpan> A foundational exploration of essential cooking techniques and the execution of key dietary staples. This module provides the technical knowledge needed to build confidence, efficiency, and intuition in the kitchen.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #7: “Cooking Classes”</BoldSpan> A hands-on experience designed to refine essential kitchen skills, including knife handling, flavor development, waste reduction, and mindful eating. This module enhances both technique and intuition, fostering confidence in the kitchen.
                </Text>

                <Text {...styles.subTitleText} paddingBottom={"10px"}> <BoldSpan {...styles.Bold}>Blueprint Structure</BoldSpan></Text>
                <Text {...styles.paragraphText} paddingBottom={"0px"}>
                    The <BoldSpan {...styles.Bold}>one-year journey</BoldSpan> is strategically structured to ensure steady progress, skill refinement, and long-term growth.
                </Text>
                <Text {...styles.paragraphText}>
                    <StyledList>
                        <ListItem><BoldSpan {...styles.Bold}>Months 1–3: Foundation & Implementation (24 Sessions)</BoldSpan> During the first three months, we will meet <BoldSpan {...styles.Bold}>twice per week</BoldSpan> to establish strong momentum and ensure a solid foundation. Each module will be fully taught and implemented, allowing clients to gain a deep understanding of key concepts while integrating them into their daily routines.</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 4–6: Skill Refinement & Strategic Guidance (12 Sessions)</BoldSpan> As clients transition to a more independent practice, they will receive personalized <BoldSpan {...styles.Bold}>weekly</BoldSpan> goals designed to challenge their skills and foster continuous growth. One-on-one consultations will focus on reviewing their experiences, addressing challenges, and refining strategies, providing in-depth feedback and tailored adjustments to ensure lasting progress.</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 7–9: Precision Refinement & Optimization (6 Sessions)</BoldSpan> Biweekly strategy sessions will refine and optimize each client’s unique approach to cooking, meal planning, and kitchen efficiency. Based on individual progress, we will assess areas for improvement, introduce tailored strategies, and fine-tune systems to support long-term sustainability and seamless integration into their lifestyle.</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 10–12: Long-Term Sustainability (3 Sessions)</BoldSpan> In the final phase, <BoldSpan {...styles.Bold}>monthly</BoldSpan> strategy sessions will provide ongoing support, refinement, and reinforcement of key concepts. These sessions will evaluate progress, solidify essential strategies, and address any remaining challenges—ensuring lasting success in the kitchen and the confidence to sustain a refined culinary lifestyle.</ListItem>
                    </StyledList>
                </Text>

                <Text {...styles.subTitleText}>
                    <BoldSpan {...styles.Bold}>
                        Session Format
                    </BoldSpan>
                </Text>

                <Text {...styles.paragraphText}>
                    All sessions during the first three months will be held in person at my office, a thoughtfully designed space for culinary instruction, to establish a strong foundation and provide personalized guidance.
                </Text>
                <Text {...styles.paragraphText}>
                    After this initial phase, sessions will transition to structured one-on-one consultations via phone, ensuring continued progress while maintaining a carefully designed schedule that supports your objectives. If an in-person meeting at my office is required, I will notify the client in advance.
                </Text>
                <Text {...styles.paragraphText}>Session formats are intentionally structured to uphold the highest level of effectiveness and consistency, ensuring a seamless and results-driven experience.
                </Text>
                
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Session Duration</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    For the first three months, we will meet twice per week to accelerate progress and reinforce key concepts. Each week will include one <BoldSpan {...styles.Bold}>one-hour</BoldSpan> session focused on high-impact learning and one <BoldSpan {...styles.Bold}>two-hour</BoldSpan> session dedicated to deeper exploration and application. These sessions are structured for maximum efficiency and results-driven engagement. Clients are expected to arrive on time, prepared, and fully engaged to maximize the value of each session.
                </Text>
                <Text {...styles.paragraphText}>
                    After the initial three months, all sessions will transition to a standard <BoldSpan {...styles.Bold}>one-hour</BoldSpan> format, providing ongoing refinement, progress reviews, and strategic support.

                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Session Commitment</BoldSpan></Text>

                <Text {...styles.paragraphText}>
                    To ensure optimal results, clients are responsible for prioritizing and completing all scheduled sessions within the designated timeframe for each quarter. The number of sessions allocated per quarter is <BoldSpan {...styles.Bold}>fixed and cannot be modified.</BoldSpan> No additional sessions will be provided beyond those outlined in the blueprint.
                </Text>
                <Text {...styles.paragraphText}>                
                    This is an exclusive, one-year structured experience that concludes upon completion of the final session. No extensions or exceptions will be granted.
                </Text>

                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Availability</BoldSpan></Text>
                <Text {...styles.paragraphText} paddingBottom={'0px'}>
                    Begin your journey on one of the following triennial start dates:
                </Text>
                <Text {...styles.paragraphText} paddingBottom={'10px'}>
                    <StyledList>
                        <li>January 5th</li>
                        <li>April 5th</li>
                        <li>July 5th</li>
                    </StyledList>
                </Text>
                <Text {...styles.paragraphText}>
                    To ensure dedicated, personalized support, I work with a select group of four clients per cycle. If your preferred start date is fully booked, you may request to join the waitlist. However, as cancellations are rare and spots are limited, it is recommended to secure the next available date within the same year or reserve a place for the following year.
                </Text>
                <Text {...styles.paragraphText}>
                    If a scheduled start date falls on a weekend, the program will commence on the next available business day to maintain continuity and ensure a smooth experience.
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Investment</BoldSpan></Text>
                <Text {...styles.paragraphText}>                
                    <BoldSpan {...styles.Bold}>Each session</BoldSpan> is priced at $780, reflecting the bespoke culinary mentorship, specialized knowledge, and high-touch support you will receive. This is an exclusive, concierge-level experience designed for those committed to elevating a refined, sustainable, and enriching culinary lifestyle.
                </Text>
                <Text {...styles.paragraphText}>                
                    To secure your place, a 50% deposit is required at enrollment, with the remaining balance due no later than 30 days before the start date. Clients may also opt to pay in full at enrollment for a seamless, uninterrupted experience to begin with clarity and focus. Those enrolling within 30 days of the start date must pay the full fee upfront.
                </Text>
                <Text {...styles.paragraphText}>                
                    All payments are non-refundable, and deferrals will not be granted under any circumstances. If unable to attend, the Client may request approval to transfer their full reservation to another individual. This request must be submitted in writing, and approval is at the sole discretion of Le Savoir Fare. The Client is solely responsible for securing a replacement.
                </Text>
                <Text {...styles.paragraphText}>                
                    Transfer requests must be submitted at least 30 days before the start date. Requests made after this deadline will not be considered. By enrolling, the Client acknowledges full financial responsibility, regardless of unforeseen circumstances, including but not limited to medical emergencies, personal conflicts, scheduling conflicts, travel disruptions, or financial hardship.
                </Text>
                <Text {...styles.paragraphText}>                
                This investment reflects a commitment to cultivating culinary confidence, intentional nourishment, and a thoughtfully integrated approach to sustainable living. This exclusive culinary mentorship is a private, educational experience and is not an accredited culinary school. No certifications, diplomas, or professional licenses are issued upon completion.
                </Text>

                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Ready to begin?</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    Visit the <LinkSpan onClick={() => navigate("/contact-us")}>Connect</LinkSpan> page to schedule your introductory meeting and embark on this transformative journey. I look forward to partnering with you :)
                </Text>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const ListItem = styled.li`
    margin-bottom: 25px; /* Adds 10px spacing below each list item */
    
    &:last-child {
        margin-bottom: 0; /* Removes margin for the last item */
    }
`;

const BoldSpan = React.memo(styled.span`
    font-family: ${fonts.cormorandSemiblold}
`);

const LinkSpan = React.memo(styled.a`
    text-decoration: underline 1px;
    cursor: pointer;
    color: rgb(77,87,64);
    &:visited {
        color: inherit;
    }
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "60px"
    },
    CitrusImage: {
        width: "100%"
    },
    TextContainer: {
        paddingTop: "35px",
        paddingLeft: ["30px","60px"],
        paddingRight: ["30px","60px"],
        maxWidth: "900px",
        flexDirection: "column",

    },
    paragraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "20px",
        color: colors.olive,
        paddingBottom: "30px",
        lineHeight: 1.5,
        textAlign: [null, "justify"],
    },
    subTitleText: {
        fontSize: "26px",
        color: colors.olive,
        paddingBottom: "16px",
        lineHeight: 1.5,
        fontFamily: fonts.cormorand,
    },
    Bold: {
        fontFamily: fonts.cormorandBold,
    }
}