import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import lsfInitials from "../../assets/images/LSF_initials_copper_circle.png";
import styled from "styled-components";

const yenRomanesco = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743370787/yen_romanesco_zhww5t.jpg';

export default function ContactUs() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Flex {...styles.Body}>
                <Flex {...styles.ContentContainer}>
                    <Image src={yenRomanesco} {...styles.YenKnifeImage}/>
                    <Flex {...styles.RightContainer}>
                        <Image src={lsfInitials} {...styles.lsfInitialsImage}/>
                        <Text {...styles.ContactText} paddingTop={["0px", "130px"]}>
                            <BoldSpan {...styles.Bold}>Email:</BoldSpan> lesavoirfare@gmail.com
                        </Text>
                        <Text {...styles.ContactText}>
                            <BoldSpan {...styles.Bold}>Location:</BoldSpan> San Francisco, CA
                        </Text>
                        <Text {...styles.KeepInMindText} paddingTop="40px" paddingBottom="0px"> Thank you for your interest, I look forward to connecting with you!</Text>
                        <Text {...styles.KeepInMindText} paddingTop="0px">Please allow up to three business days for a response regarding your initial consultation.</Text>

                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const BoldSpan = React.memo(styled.span`
    font-family: ${fonts.cormorandSemiblold}
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    Body: {
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
        width: "100vw"
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    ContentContainer: {
        flexDirection: ["column-reverse", "row"],
        backgroundColor: "rgb(212,214,209)",
        width: "100%"
    },
    YenKnifeImage: {
        width: ["100vw", "600px"],
    },
    RightContainer: {
        backgroundColor: "rgb(212,214,209)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        position: "relative",
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    KeepInMindContainer: {
        
    },
    KeepInMindText: {
        fontFamily: fonts.cormorandLight,
        color: colors.olive,
        fontSize: "18px",
        maxWidth: ["90vw", "500px"],
        lineHeight: 1.5,
        paddingTop: "40px",
        textAlign: "center",
        paddingBottom: ["40px", null]
    },
    ContactText: {
        fontFamily: fonts.cormorand,
        color: colors.olive,
        fontSize: "18px",
        paddingBottom: "10px"
    },
    Bold: {
        fontFamily: fonts.cormorandSemiblold,
    },
    lsfInitialsImage: {
        width: "200px",
        paddingBottom: "40px",
        position: ["relative", "absolute"],
        top: [null, "90px"],
    }
    
}