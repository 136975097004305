import React, {useState, useContext} from "react";
import Flex from "./Flex";
import Text from "./Text";
import Image from "./Image";
import {colors, fonts} from "./theme/theme";
import {useNavigate} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AudioContext } from '../../context/AudioContext';

import lsfLogo from "../../assets/images/LeSavoirFareLOGO.png";
import instagram from "../../assets/images/copperinstagramLOGO.jpeg";
import muteIcon from '../../assets/images/mute.svg';
import unmuteIcon from '../../assets/images/unmute.svg';

import HeaderMobile from "./HeaderMobile";
import headerLinks from "./headerLinks";

const useLogoWidth = () => {
  const [width, setWidth] = React.useState("509px");

  React.useEffect(() => {
    const mediaQuery1300 = window.matchMedia("(max-width: 1300px)");
    const mediaQuery990 = window.matchMedia("(max-width: 990px)");
    
    const handleWidthChange = () => {
      if (mediaQuery990.matches) {
        setWidth("220px");
      } else if (mediaQuery1300.matches) {
        setWidth("380px");
      } else {
        setWidth("509px");
      }
    };

    handleWidthChange(); // Initial check
    mediaQuery1300.addEventListener('change', handleWidthChange);
    mediaQuery990.addEventListener('change', handleWidthChange);

    return () => {
        mediaQuery1300.removeEventListener('change', handleWidthChange);
      mediaQuery990.removeEventListener('change', handleWidthChange);
    };
  }, []);

  return width;
};

export default function Header() {
    const logoWidth = useLogoWidth();
    const { isPlaying, togglePlay } = useContext(AudioContext);

    if (isMobile) {
        return (
            <HeaderMobile/>
        );
    }

    return (
        <Flex {...styles.Container}>
            <Flex {...styles.LeftContainer}>
                <Image src={lsfLogo} width={logoWidth}/>
            </Flex>
            <Flex {...styles.RightContainer}>
                <Flex {...styles.WebMenuContainer}>
                    {headerLinks.map((menuItem) => {
                        return (
                            <WebMenuButton {...menuItem}/>
                        );
                    })}
                    <Image 
                        src={isPlaying ? unmuteIcon : muteIcon} 
                        {...styles.instagramIcon}
                        onClick={togglePlay}
                    />
                    <Image 
                        src={instagram} 
                        {...styles.instagramIcon}
                        onClick={() => window.open("https://www.instagram.com/lesavoir.fare/")}
                    />

                </Flex>
            </Flex>
        </Flex>
    );
}

const WebMenuButton = function ({label, path}) {

    const navigate = useNavigate();
    const [hovering, setHovering] = useState(false);
    const selected = window.location.pathname == path;
    const highlightButton = selected || hovering;

    return (
        <Flex 
            {...styles.WebMenuButton}
            cursor={"pointer"}
            onMouseEnter={() => {setHovering(true)}}
            onMouseLeave={() => {setHovering(false)}}
            onClick={() => {
                navigate(path);
            }}
        >
            {
                highlightButton ? 
                    <Flex {...styles.WebMenuButtonBarSelected}/> : 
                    <Flex {...styles.WebMenuButtonBarUnselected}/>
            }
            <Text
                color={highlightButton ? colors.blueHighlighter : colors.olive}
                {...styles.WebMenuButtonText}  
            >
                {label}
            </Text>
        </Flex>
    );
}


const styles = {
    Container: {
        width: "100vw",
        height: "84px",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "48px",
        marginBottom: "48px",
        alignItems: "center",
        zIndex: 1,
    },
    LeftContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    RightContainer: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        minWidth: "600px",
    },
    WebMenuContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "84px",
    },
    WebMenuButton: {
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginLeft: "5px",
        marginRight: "5px",
        position: "relative",
        fontFamily: fonts.robotoLight,
        cursor: "pointer"
    },
    WebMenuButtonText: {
        fontSize: "16px",
    },
    WebMenuButtonBarSelected: {
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        width: "100%",
        height: "4px",
        backgroundColor: colors.olive,
    },
    WebMenuButtonBarUnselected: {
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        width: "100%",
        height: "1px",
        backgroundColor: "rgb(217,219,215)",
    },
    instagramIcon: {
        width: "16px",
        height: "16px",
        cursor: "pointer",
        paddingLeft: "10px"
    },
};
