import React from "react";
import Flex from "../ui/Flex";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import styled from "styled-components";

const lettuceBackground = "https://res.cloudinary.com/dpfmm0omx/image/upload/v1743379144/lettuce_background_i8hjiw.png"
const oysters = 'https://res.cloudinary.com/dpfmm0omx/image/upload/v1743379009/pinkoysters_gysemw.jpg';
const yen = "https://res.cloudinary.com/dpfmm0omx/image/upload/v1743378887/yen_long_r0qtis.jpg";
const linkedin = "https://res.cloudinary.com/dpfmm0omx/image/upload/v1743370588/linkedin_wfkzn1.webp";
const leaf = "https://res.cloudinary.com/dpfmm0omx/image/upload/v1743370587/leafSquare_tzi7wp.jpg";

export default function AboutUs() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>About Us</Text>
            <Flex {...styles.AboutUsContainer}>
                <Flex {...styles.LeftAboutUsContainer}>
                    <Image src={leaf} {...styles.LeafImage}/>
                    <Flex {...styles.GoalsTextContainer}>
                    <Text {...styles.TitleText} {...{
                        color: "rgb(212, 214,209)",
                        paddingBottom: ["30px", "35px"],
                        }}>Is this the right fit for you?</Text>
                        <Text {...styles.GoalsBodyText}>
                            Le Savoir Fare is designed for those ready to take an active role in transforming their relationship with food. They are devoted to intentional nourishment and value the thoughtfulness behind every meal. Whether building a strong foundation or refining their existing practice, they seek to deepen their culinary skills while embracing clean living and supporting regenerative agriculture.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Regardless of dietary preferences or skill level, this experience is designed to elevate culinary confidence and expertise. Clients recognize that creating a truly sustainable culinary lifestyle requires time, effort, and intention. Rather than seeking a temporary solution, they are committed to a lasting, transformative shift.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            This journey requires active participation—along with curiosity, dedication, and a willingness to experiment and evolve. Wherever they begin, each client gains the skills, confidence, and personalized systems needed to thoughtfully integrate nourishing meals into their daily routine.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            For those who resonate with this approach, the next step begins with exploring the 
                            {" "}<BioLink target="_blank" href="/blueprint">blueprint</BioLink>{" "}!

                        </Text>
                        

                    </Flex>
                    
                </Flex>
                <Flex {...styles.RightAboutUsContainer}>
                    <Flex {...styles.VisionTextContainer}>
                        <Text {...styles.VisionParagraph}>
                            Le Savoir Fare is a private culinary consultancy offering a white-glove experience for those seeking an elevated, intuitive, and intentional approach to nourishment—carefully designed to complement and enhance their lifestyle.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            Founded and led by Yennifer, the consultancy provides a bespoke, immersive, and science-driven culinary experience. With a background in fine dining, nutrition science, and psychology, she collaborates closely with each client, crafting an experience that empowers them to master their kitchen, optimize their health, and cultivate a profound connection to food.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            This service extends far beyond traditional cooking lessons. Clients receive a hands-on, concierge-level transformation that encompasses kitchen optimization, curated premium ingredient sourcing, tailored culinary planning, and mindful eating strategies. Every element is meticulously designed to integrate high-quality, nutrient-rich meals into daily life.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            Rooted in clean living and regenerative agriculture, this approach ensures that each meal, ingredient, and technique is intentionally selected to foster regenerative health—for both clients and the ecosystem.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                        Limited to only 12 clients per year, this exclusive, high-touch service guarantees unparalleled personalization. More than just  cooking skills, it redefines a culinary mindset, equipping clients with the skills and confidence to reclaim their time through streamlined systems and thoughtfully incorporate nourishing meals into their daily routine.
                        </Text>
                    </Flex>
                    <Flex {...styles.LettuceContainer}>
                        <Image src={lettuceBackground} {...styles.LettuceBackgroundImage}/>
                        <Image src={oysters} {...styles.OysterImage}/>

                    </Flex>
                </Flex>
            </Flex>
            <Flex {...styles.YenImageContainer}>
                <Image src={yen} {...styles.YenImage}/>
                <Text {...styles.YenniferText}>Yennifer Prada</Text>
            </Flex>
            <Flex {...styles.BioContainer}>
                <Flex {...styles.BioTextContainer}>
                    <Text {...styles.BioText}>
                        Chef Yennifer Prada was born and raised in Venezuela, with Ecuadorian and Colombian heritage. Coming from a family of skilled cooks, she grew up immersed in a rich culinary tradition that blended the flavors of all three cultures. At 17, she moved to the United States to pursue a Bachelor of Science in Psychology. Recognizing the profound connection between nutrition and mental health, she continued her education with an Associate of Science in Culinary Arts in Los Angeles.
                    </Text>
                    <Text {...styles.BioText}>
                        While volunteering at the
                        {" "}<BioLink target="_blank" href="https://www.lexusallstarchefclassic.com/">All-Star Chef's Classic</BioLink>{" "}
                        in Los Angeles, her talent and dedication caught the attention of {" "}<BioLink target="_blank" href="https://www.instagram.com/chefludo/">Chef Ludo Lefebvre</BioLink>, who offered her a position at 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petittroisla/">Petit Trois</BioLink>. She was soon promoted to 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/troismecla/">Trois Mec</BioLink>, his Michelin-starred restaurant, where she honed her skills under his mentorship. Seeking new challenges, she accepted the role of opening Junior Sous Chef at
                        {" "}<BioLink target="_blank" href="https://blacksheeprestaurants.com/">Artemis & Apollo</BioLink> in Hong Kong, where her leadership and expertise led to a rapid promotion to sole Sous Chef. The restaurant earned recognition as one of CNN’s ‘Best New Restaurants in Hong Kong’ for 2019 and received acclaim from Tatler Asia and Time Out Hong Kong for its authentic Greek cuisine.
                    </Text>
                    <Text {...styles.BioText}>
                        Upon returning to the United States, she moved to San Francisco to join
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/dominiquecrenn/">Chef Dominique Crenn</BioLink>, at her Michelin-starred restaurant, 
                        {" "}<BioLink target="_blank" href="https://www.barcrenn.com/">Bar Crenn</BioLink>. When the COVID-19 pandemic led to the restaurant's temporary closure, she pivoted to address the growing food insecurity crisis, taking on the role of Culinary Manager for the 
                        {" "}<BioLink target="_blank" href="https://www.rethinkfood.org/">Rethink Food Project</BioLink>, operating from
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petitcrenn/">Petit Crenn</BioLink>. 
                    </Text>
                    <Text {...styles.BioText}>
                        Beyond her culinary expertise, she is deeply engaged in the science of food and nutrition. She holds certificates in Science of Nutrition from the Stanford Center for Health Education and Plant-Based Nutrition from the T. Colin Campbell Center for Nutrition Studies—both highly regarded for their evidence-based approach to health and sustainability.
                    </Text>
                    <Text {...styles.BioText}>
                        Committed to social and ecological renewal, she weaves sustainability into both her culinary philosophy and lifestyle. Through Le Savoir Fare, she merges her fine dining expertise with a dedication to conscious eating, inspiring others to cultivate a more mindful and sustainable approach to food.
                    </Text>
                    <a href="https://www.linkedin.com/in/yennifer-prada-3500a69a" target="_blank">
                        <Image 
                            src={linkedin}
                            {...styles.LinkedinImage}
                        />
                    </a>

                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const BioLink = React.memo(styled.a`
    text-decoration: underline 1px;
    cursor: pointer;
    color: rgb(254,250,234);
    &:visited {
        color: inherit;
    }
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: ["30px", "90px"]
    },
    AboutUsContainer: {
        flexDirection: ["column-reverse", "row"],
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100vw",
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
    },
    LeftAboutUsContainer: {
        backgroundColor: "rgb(62,82,84)",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        height: [null, "1680px"]
    },
    RightAboutUsContainer: {
        backgroundColor: "rgb(206,210,201)",
        flexDirection: "column",
        justifyContent: ["center", "flex-start"],
        alignItems: "center",
        width: "100%",
        zIndex: 1,
        height: [null, "1680px"]
    },
    LeafImage: {
        height: ["300px", "650px"],
        borderRadius: "50%",
        position: "absolute",
        top: "50px",
        right: ["50%", "-150px"],
        marginRight: ["-150px", null],
        zIndex: "0",
    },
    LettuceContainer: {
        maxWidth: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: ["40px", null]
    },
    OysterImage: {
        maxWidth: ["90vw", "80%"],
        height: ["450px", "650px"],
        position: "absolute",
        objectFit: "contain"
    },
    LettuceBackgroundImage: {
        maxWidth: ["100vw", "90vw"],
        height: ["500px", "780px"]    
    },
    GoalsTextContainer: {
        paddingTop: ["400px","700px"], // 650 picture size + 50 padding at top + 90 padding below pic
        paddingBottom: [null, "50px"],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: ["85vw", "500px"],
    },
    GoalsTitleText: {
        fontFamily: fonts.cormorandMedium,
        color: "rgb(212, 214,209)",
        fontSize: "33px",
        width: "400px",
        paddingBottom: "20px"
    },
    GoalsBodyText: {
        fontFamily: fonts.cormorand,
        color: "rgb(212, 214,209)",
        fontSize: ["18px", "20px"],
        paddingBottom: ["35px","45px"],
        lineHeight: 1.3,
        textAlign: "justify",
    },
    zeroFoodprintImage: {
        width: "92px",
    },
    zeroFoodprintContainer: {
        flexDirection: "row",
        justifyContent: ["center", "flex-end"],
        alignItems: "center",
        width: "575px",
        paddingBottom: ["60px", "164px"],
        marginTop: ["40px", "-50px"]
    },
    VisionTextContainer: {
        flexDirection: "column",
        maxWidth: ["85vw", "550px"],
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        paddingTop: ["40px", "60px"]
    },
    VisionTitleText: {
        color: colors.olive,
        fontFamily: fonts.cormorandMedium,
        fontSize: "33px",
        paddingBottom: "65px",
        display: ["none", "block"]
    },
    VisionParagraph: {
        color: colors.olive,
        fontFamily: fonts.cormorand,
        fontSize: ["18px", "20px"],
        paddingBottom: "40px",
        lineHeight: "25px",
        textAlign: "justify",
    },
    YenImageContainer: {
        width: "100%",
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        position: "relative"
    },
    YenImage: {
        width: "100%"
    },
    YenniferText: {
        position: "absolute",
        fontFamily: fonts.cormorandMedium,
        fontSize: ["45px", "68px"],
        color: [colors.beigeBackground, "#DBC5A4"],
        right: ["0%", "18%"],
        lineHeight: [1, null],
        maxWidth: ["180px", "250px"],
        textAlign: ["center", "center"],
        top: ["4%", "30%"]
    },
    BioContainer: {
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        width: "100%",

    },
    BioTextContainer: {
        width: "100%",
        backgroundColor: "rgb(77,87,65)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
        textAlign: 'justify',
    },
    BioText: {
        color: "rgb(254,250,234)",
        fontSize: ["18px", "20px"],
        fontFamily: fonts.cormorandMedium,
        paddingBottom: "25px",
        maxWidth: "960px",
        lineHeight: 1.5,
    },
    LinkedinImage: {
        paddingBottom: "35px",
        cursor: "pointer",
    },
}