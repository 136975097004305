export default [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "About Us",
        path: "/about-us"
    },
    {
        label: "Blueprint",
        path: "/blueprint"
    },
    // {
    //     label: "Gallery",
    //     path: "/gallery"
    // },
    {
        label: "Contributors",
        path: "/contributors"
    },
    {
        label: "Giving",
        path: "/giving"
    },
    {
        label: "Connect",
        path: "/contact-us"
    }

];